// 公用接口
import request from '@/router/axios';
import { pvhApiBase, businessUrl,textileBaseUrl } from '@/config/env';
//纺纱 下拉框
export const ProcessDropDownList = (remark, param1, param2) => {
    return request({
        url: pvhApiBase + "ProcessManagement/GetUnifiedDropDowns",
        method: "get",
        params: {
            "remark": remark,
            "param1": param1,
            "param2": param2
        }
    })

}
//追溯 下拉框
export const TracingDropDownList = (remark, param1, param2) => {
    return request({
        url: pvhApiBase + "Tracing/GetUnifiedDropDowns",
        method: "get",
        params: {
            "remark": remark,
            "param1": param1,
            "param2": param2
        }
    })

}

//追溯 返回是否
export const TPurchaseUnifiedIsBool = (remark, id, param1, param2, param3) => {
    return request({
        url: textileBaseUrl + "purchase/Purchase/UnifiedReturnBool",
        method: "get",
        params: {
            "remark": remark,
            "id": id,
            "param1": param1,
            "param2": param2,
            "param3": param3,
        }
    })

}
//追溯 返回是否
export const TSalesUnifiedIsBool = (remark, id, param1, param2, param3) => {
    return request({
        url: textileBaseUrl + "sales/Sales/UnifiedReturnBool",
        method: "get",
        params: {
            "remark": remark,
            "id": id,
            "param1": param1,
            "param2": param2,
            "param3": param3,
        }
    })

}
//追溯 返回是否
export const TracingUnifiedIsBool = (remark, id, param1, param2, param3) => {
    return request({
        url: pvhApiBase + "Tracing/UnifiedReturnBool",
        method: "get",
        params: {
            "remark": remark,
            "id": id,
            "param1": param1,
            "param2": param2,
            "param3": param3,
        }
    })

}

//追溯 帮助返回错误详情
export const TracingUnifiedHelpReturn = (remark, id, param1, param2, param3) => {
    return request({
        url: pvhApiBase + "Tracing/GetUnifiedHelpReturn",
        method: "get",
        params: {
            "remark": remark,
            "id": id,
            "param1": param1,
            "param2": param2,
            "param3": param3,
        }
    })

}

//追溯 返回报表数据
export const TracingCharts = (remark, param1, param2, param3, param4, param5, param6, param7) => {
    return request({
        url: pvhApiBase + "Tracing/GetUnifiedChartsReturn",
        method: "get",
        params: {
            "remark": remark,
            "param1": param1,
            "param2": param2,
            "param3": param3,
            "param4": param4,
            "param5": param5,
            "param6": param6,
            "param7": param7,
        }
    })

}

//追溯 返回报表数据
export const UnifiedHelpReturnInfo = (remark, param1, param2, param3, param4, param5, param6, param7) => {
    return request({
        url: pvhApiBase + "Tracing/GetUnifiedHelpReturnInfo",
        method: "get",
        params: {
            "remark": remark,
            "param1": param1,
            "param2": param2,
            "param3": param3,
            "param4": param4,
            "param5": param5,
            "param6": param6,
            "param7": param7,
        }
    })

}

//获取当前公司信息
export const corpinfo = () => {
    return request({
        url: pvhApiBase + "ProcessManagement/GetCurrCorporation",
        method: "get",
    })

}
//获取参数公司信息
export const Getcorpinfo = (id) => {
    return request({
        url: pvhApiBase + "ProcessManagement/GetCorporationById",
        method: "get",
        params: {
            "id": id,
        }
    })

}
Getcorpinfo
//根据类别获取 List<string>
export const TracingStringList = (remark, param1, param2, param3) => {
    return request({
        url: pvhApiBase + "Tracing/GetUnifiedStringList",
        method: "get",
        params: {
            "remark": remark,
            "param1": param1,
            "param2": param2,
            "param3": param3,
        }
    })
}

export function getDateYYYMMDDhhmmss(originVal)
{
    const dt = new Date(originVal)
    const y = dt.getFullYear()
    const m = (dt.getMonth() + 1 + '').padStart(2, '0')
    const d = (dt.getDate() + '').padStart(2, '0')
  
    const hh = (dt.getHours() + '').padStart(2, '0')
    const mm = (dt.getMinutes() + '').padStart(2, '0')
    const ss = (dt.getSeconds() + '').padStart(2, '0')
    // yyyy-mm-dd hh:mm:ss
    return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
  }
export function getDateYYYMMDD(d) {
    var date = new Date(d); //日期对象
    var yTime = date.getFullYear();
    var MTime = date.getMonth() + 1;
    var dTime = date.getDate();
    if (MTime < 10) {
        MTime = "0" + MTime;
    }
    if (dTime < 10) {
        dTime = "0" + dTime;
    }
    return yTime + "-" + MTime + "-" + dTime ;
}

export function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
}

export function Getguid() {
    return (S4() + S4() + "-" +S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}

//新增物料
export const ProcessAddProduct = (row) => request({
    url: businessUrl + "product/pvhInsert",
    method: "post",
    data: {
        ...row
    }
})

//获取版本号
export const GetBusinessNoIn = (code,corpId) => request({
    url: businessUrl + "codeRule/getStringByRuleCode",
    method: "post",
    params: {
        "code": code,
        "corpId": corpId,
    }
})


//给状态信息
export function GetStatusName(statusCode)
{
    var s_Status = "";
    switch (statusCode) {
        case -1:
            s_Status = this.$t('Delete');
            break;
        case 0:
            s_Status = this.$t('initial');
            break;
        case 110:
            s_Status = this.$t('initial');
            break;
        case 120:
            s_Status = this.$t('initial');
            break;
        case 130:
            s_Status = this.$t('initial');
            break;
        case 140:
            s_Status = this.$t('under order');
            break;
        case 150:
            s_Status = this.$t('under declaration');
            break;
        case 320:
            s_Status = this.$t('Pending');
            break;
        case 410:
            s_Status = this.$t('Be rejected');
            break;
        case 550:
            s_Status = this.$t('Resubmit');
            break;
        case 605:
            s_Status = this.$t('Locked');
            break;
        case 650:
            s_Status = this.$t('Part approved');
            break;
        case 660:
            s_Status = this.$t('Approved');
            break;
        case 670:
            s_Status = this.$t('Reviewed');
            break;
        default:
            s_Status = this.$t('unknown');
    }
    return s_Status;
}
